<template>
  <div v-if="description?.length" class="info-box center">{{ description }}</div>

  <form @submit.prevent="formAction">
    <div v-if="phoneConfirmationInitialized" class="row">
      <div class="col offset-s3 s6">
        <div class="center mb-2">Bitte geben Sie den Bestätigungs-Code ein, den Sie per SMS erhalten haben.</div>
        <InputField inline required v-model="input.phoneVerification" id="phoneVerification" label="Bestätigungs-Code" />
      </div>
    </div>
    <div v-else>
      <div v-if="configuration.name" class="row">
        <InputField required v-model="input.firstName" id="firstName" label="Vorname" class="m6" />
        <InputField required v-model="input.lastName" id="lastName" label="Nachname" class="m6" />
      </div>

      <div v-if="configuration.street" class="row">
        <InputField required v-model="input.street" id="street" label="Straße" class="m6" />
        <InputField required v-model="input.streetNumber" id="streetNumber" label="Hausnummer" class="m6" />
      </div>

      <div v-if="configuration.town" class="row">
        <InputField required v-model="input.postalCode" id="postalCode" label="Postleitszahl" class="m6" />
        <InputField required v-model="input.town" id="town" label="Ort" class="m6" />
      </div>

      <div v-if="configuration.email" class="row">
        <InputField required v-model="input.email" id="email" label="E-Mail" type="email" />
      </div>

      <div v-if="configuration.phone || configuration.phoneVerification" class="row">
        <InputField required v-model="input.phone" id="phone" label="Telefonnummer" type="tel" pattern="^\+?[\d\-\(\) ]*$" />
      </div>

      <div v-if="configuration.phoneVerification" class="row mb-1">
        <div class="col s12 flex-row flex-hcenter">
          <i class="material-icons grey-text mr-1">info</i>
          <span class="grey-text text-darken-1">
            Um diese Datei herunterzuladen, müssen Sie Ihre Telefonnummer bestätigen. Nach Absenden des Formulars erhalten Sie einen Code per SMS.
            Bitte geben Sie diesen im nächsten Schritt ein, um Zugriff auf die Datei zu erhalten.
          </span>
        </div>
      </div>

      <div v-if="configuration.confirmation" class="section row mt-1">
        <div class="col s12">
          <Checkbox required alt v-model="input.confirmation" class="left-align grey-text text-darken-4">
            Hiermit bestätige ich die oben genannten Bedingungen gelesen und verstanden zu haben und stimme diesen ausdrücklich zu.
          </Checkbox>
        </div>
      </div>

      <div class="section row">
        <div class="col s12">
          <Checkbox required alt v-model="input.privacyPolicyAccepted" class="left-align grey-text text-darken-4">
            Ich akzeptiere die <a href="/privacy" target="_blank">Datenschutzbestimmungen</a> und stimme der Speicherung meiner personenbezogenen Daten ausdrücklich zu.
          </Checkbox>
        </div>
      </div>
    </div>

    <div class="section mt-1">
      <div class="flex-row flex-center">
        <button :disabled="busy" type="submit" class="waves-effect waves-light btn-large">
          {{ phoneVerificationNeeded? "Telefonnummer bestätigen": "Datei anfordern" }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import Checkbox from "@/components/Checkbox.vue";
import InputField from "@/components/InputField.vue";
import { actions, events, http, routes } from "@/constants.js";

export default {
  name: "DownloadAccessForm",
  emits: [events.ACCESS_GRANTED],
  data() {
    return {
      input: {
        firstName: null,
        lastName: null,
        street: null,
        streetNumber: null,
        postalCode: null,
        town: null,
        email: null,
        phone: null,
        confirmation: false,
        privacyPolicyAccepted: false,
        phoneVerification: null
      },
      phoneConfirmationInitialized: false
    };
  },
  props: {
    configuration: {
      type: Object,
      required: true
    },
    description: {
      type: String,
      default: ""
    }
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    },
    phoneVerificationNeeded() {
      return this.configuration.phoneVerification && !this.phoneConfirmationInitialized;
    },
    formAction() {
      return this.phoneVerificationNeeded? this.initializePhoneVerification: this.submitForm;
    }
  },
  methods: {
    async submitForm() {
      const pageId = this.$route.params.id;
      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.DOWNLOAD_PAGE.get(pageId),
        method: http.POST,
        body: this.input
      });

      if (result !== null) this.$emit(events.ACCESS_GRANTED, result.files);
    },
    async initializePhoneVerification() {
      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.DOWNLOAD_PAGE.PHONE_VERIFICATION,
        method: http.POST,
        body: this.input
      });

      if (result === null) return;

      this.phoneConfirmationInitialized = true;
    }
  },
  components: {
    Checkbox,
    InputField
  }
};
</script>

<style module lang="scss">

</style>
