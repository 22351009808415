<template>
  <div :class="$style.container" class="center">
    <label for="download">{{ file.name }} ({{ fileSize }})<br>Hochgeladen am {{ uploadDate }}</label>
    <a :href="file.url" :download="file.name" id="download" target="_blank" class="waves-effect waves-light btn-large" :class="$style.button">Download</a>
  </div>

  <div class="flex-row flex-center">
    <img v-if="file.type == 'image'" :src="file.url" :alt=file.title>
    <iframe v-else :src="file.url" width="100%" height="900" type="application/pdf"/>
  </div>
</template>

<script>
import filesize from "filesize";

export default {
  name: "DownloadPageContent",
  props: {
    files: {
      type: Object,
      required: true
    }
  },
  computed: {
    file() {
      return this.files[0];
    },
    fileSize() {
      return filesize(this.file.size);
    },
    uploadDate() {
      return new Date(this.file.createdAt).toLocaleString();
    }
  }
};
</script>

<style module lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 3rem 0;
  }

  .button {
    min-width: 30%;
    margin: auto;
    margin-top: 0.5rem;
  }
</style>
