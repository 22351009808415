<template>
  <div v-if="downloadPage">
    <h1>{{ downloadPage.title }}</h1>
    <div class="row">
      <div class="col s12 m8 offset-m2">
        <DownloadAccessForm v-if="!files?.length" @access-granted="this.files = $event" :description="downloadPage.description" :configuration="downloadPage.configuration" />
        <DownloadPageContent v-else :files="files" />
      </div>
    </div>
  </div>
  <Spinner v-else />

  <div class="bottom-padding"></div>
</template>

<script>
import { actions, pages, routes } from "@/constants.js";
import Spinner from "@/components/Spinner.vue";
import DownloadAccessForm from "@/components/DownloadAccessForm.vue";
import DownloadPageContent from "@/components/DownloadPageContent.vue";

export default {
  name: "DownloadAccess",
  data() {
    return {
      downloadPage: null,
      files: []
    };
  },
  async mounted() {
    const pageId = this.$route.params.id;
    const result = await this.$store.dispatch(actions.API_REQUEST, {
      route: routes.DOWNLOAD_PAGE.get(pageId),
      onError: () => this.$router.replace({ path: pages.NOT_FOUND })
    });

    if (result !== null) this.downloadPage = result;
  },
  components: {
    Spinner,
    DownloadAccessForm,
    DownloadPageContent
  }
};
</script>

<style type="scss">
  .row {
    margin-bottom: 0;
  }
</style>

<style module type="scss">

</style>
